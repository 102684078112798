<template>
  <v-app>
    <v-container fill-height v-if="!fail">
      <v-row justify="center" align="center">
        <v-col cols="12" lg="6">
          <div class="" v-if="loading">
            <div class="mx-1 my-2 display-1 success--text">
              Saving Your Score...
            </div>
            <v-progress-linear
              rounded
              height="20"
              indeterminate
              color="green"
            ></v-progress-linear>
          </div>
          <div class="display-1 success--text flex d-flex justify-center align-center" v-else>
            Score Saved
            <v-icon size="42" class="ml-5" color="success">
              mdi-check-circle-outline
            </v-icon>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data: () => ({
    fail: true,
    loading: true
  }),

  mounted() {
    //booth name
    let name = this.checkName(this.$route.params.name)
    console.log(name);
    //score
    let gen = this.$route.params.score
    let arr_score = gen.split('-');
    let score = arr_score[1];

    if(!score) window.close()
    
    this.fail = false

    this.storeBoothScore({
      name: name,
      score: score 
    }).then(() => {
      this.loading = false
      setTimeout(() => {
        window.close()
      }, 1000);
    })
    
  },
  methods: {
    ...mapActions('student', [
      'storeBoothScore'
    ]),

    checkName(name) {
      if(name.includes('1_')) {
        //gold
        let arr_name = name.split('1_');
        return arr_name[1];
      }

      if(name.includes('2_')) {
        //silver
        let arr_name = name.split('2_');
        return arr_name[1];
      }

      if(name.includes('03_')) {
        //bronze
        let arr_name = name.split('03_');
        return arr_name[1];
      }

       if(name.includes('04_')) {
        //standard
        let arr_name = name.split('04_');
        return arr_name[1];
      }

    }
  }
}
</script>